@tailwind base;
@tailwind components;
@tailwind utilities;

.hiring-h2 {
  text-decoration: rgba(0, 255, 113, 1) wavy underline;
}

@media only screen and (min-width: 375px) and (max-width: 812px) {
  #form-container {
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column; /* Stack inputs vertically */
    width: 100%; /* Full width of the container */
  }
  
  section {
    display: flex;
    flex-direction: column; /* Stack each input group vertically */
    gap: 16px; /* Space between inputs */
    width: 100%; /* Ensure sections fill the form's width */
  }
  
  .inputs {
    width: 100%; /* Ensure inputs take up full width */
  }
  
  button {
    align-self: center; /* Center the submit button */
    margin-top: 20px;
  }

  #application-submitted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media ((max-width: 1320px) or (max-height: 870px)) {
  .template-buttons {
    flex-direction: row;    
    gap: 20px;             
    padding: 12px;
    margin-bottom: 10px;
  }
  
  .reject-button {
    margin-top: 10px;
  }

  .template-dropdown-button {
    height: 40%;
    padding: 0.75px 2px;
  }

  .template-select {
    width: 200px;
  }
}

/* div.DraftEditor-root {
  border: 1px solid #000;
  background-color: beige;
  height: 200px;
  width: 300px;
  overflow-y: auto;
} */

.DraftEditor-root {
  flex: 1;
  padding-left: 1rem; /* equivalent to px-4 */
  padding-right: 1rem; /* equivalent to px-4 */
  padding-top: 0.5rem; /* equivalent to py-2 */
  padding-bottom: 0.5rem; /* equivalent to py-2 */
  background-color: #2d3748; /* equivalent to bg-gray-800 */
  color: #d1d5db; /* equivalent to text-gray-300 */
  border-radius: 0.5rem; /* equivalent to rounded-lg */
  outline: none; /* equivalent to focus:outline-none */
}

.DraftEditor-root:focus {
  box-shadow: 0 0 0 2px #38a169; /* equivalent to focus:ring-2 focus:ring-green-500 */
}

.DraftEditor-root {
  overflow-y: auto; /* equivalent to overflow-y-auto */
  height: 100%; /* equivalent to h-full */
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.thread-tab {
  text-align: center;
  border: 1px solid gray;
  margin: 0 5px;
  padding: 0 5px;
  cursor: pointer;
  width: 100px;
}

.thread-tab.selected {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

