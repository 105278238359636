.markdown-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    word-wrap: break-word;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
}

/* Headings */
.markdown-container h1 {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
}

.markdown-container h2 {
    font-size: 1.75rem;
    font-weight: bold;
}

.markdown-container h3 {
    font-size: 1.5rem;
    font-weight: bold;
}

.markdown-container h4, .markdown-container h5, .markdown-container h6 {
    font-size: 1.25rem;
    font-weight: bold;
}

/* Paragraphs */
.markdown-container p {
    margin: 0.5rem 0;
}

/* Lists */
.markdown-container ul, .markdown-container ol {
    padding-left: 2rem;
}

.markdown-container ul {
    list-style-type: disc;
}

.markdown-container ol {
    list-style-type: decimal;
}

.markdown-container li {
    margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-container blockquote {
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    border-left: 4px solid #ccc;
    background-color: #f8f8f8;
    font-style: italic;
    color: #666;
}

/* Code blocks and inline code */
.markdown-container pre {
    background-color: #282c34;
    color: #f8f8f2;
    padding: 1rem;
    border-radius: 5px;
    overflow-x: auto;
    margin: 1rem 0;
    width: fit-content;
}

.markdown-container code {
    background-color: #f5f5f5;
    color: #d63384;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
}

.markdown-container pre > code {
    background-color: #282c34;
    color: #f8f8f2;
    border-radius: 5px;
    overflow-x: auto;
    margin: 1rem 0;
}

/* Links */
.markdown-container a {
    color: teal;
    text-decoration: none;
}

.markdown-container a:hover {
    text-decoration: underline;
}

/* Images */
.markdown-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem 0;
}

/* Horizontal rules */
.markdown-container hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 2rem 0;
}

/* Tables */
.markdown-container table {
    border-collapse: collapse;
    width: 100%;
    margin: 1rem 0;
}

.markdown-container th, .markdown-container td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
}

.markdown-container th {
    background-color: #f4f4f4;
    font-weight: bold;
}
