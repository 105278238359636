/* Loader.css */
/* @keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .wolf-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.wolf-loader {
  width: 6vw;
  height: 6vw;
  animation: rotate 2s linear infinite;
} */


/*Pedro Loader*/
.wolf-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: transparent;
}

.wolf-loader {
  width: 10vw;
  height: 10vw;
  background: transparent;
  border-radius: 50%;
}